<template>
  <router-link
    :to="`/transaction/pay/${orderNumber}?uuid=${applicationUuid}&type=${actionType}`"
    v-show="isAllowed"
    class="btn btn-primary"
    >{{ buttonName }}
  </router-link>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';
export default {
  props: {
    allowedActions: {
      required: true,
      validator: (value) => {
        if (value !== null) {
          return value.constructor.name == 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    orderNumber: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: false,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: 'PAY',
      actionType: 'RENT_TRANSACTION',
    };
  },
  methods: {},
  computed: {
    isAllowed() {
      if (!this.applicationUuid) {
        return false;
      }
      const applicationType = this.type.toUpperCase(),
        actionType = this.actionType.toUpperCase(),
        actionName = this.actionName.toUpperCase();
      let allowedActions = [];
      if (this.allowedActions) {
        allowedActions = this.allowedActions.map((elem) => {
          return elem.name.toUpperCase();
        });
      }
      if (applicationType === actionType) {
        if (allowedActions.indexOf('OFFLINE_PAY') !== -1) {
          return false;
        }
        return allowedActions.indexOf(actionName) !== -1;
      } else {
        return !actionType && allowedActions.indexOf(actionName) !== -1;
      }
    },
  },
};
</script>
